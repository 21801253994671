export default {
  teams: {
    name: 'Команды',
    teams_fields: {
      title: 'Команды',
      default_team: 'Все пользователи',
      team_id: {
        title: 'ID',
      },
    },
    teams_buttons: {
      add_team: 'Добавить команду',
      add_users: 'Добавить пользователя',
      add_user: 'Добавить пользователя',
      create_user: 'Создать пользователя',
    },
    sub_nav: {
      users: 'Пользователи',
      permissions: 'Права доступа',
    },
  },
  team_users: {
    title: '0 пользователей | 1 пользователь | {count} пользователя | {count} пользователей',
    list: {
      fields: {
        name: {
          title: 'Имя',
        },
        first_name: {
          title: 'Имя',
        },
        full_name: {
          title: 'Имя',
        },
        email: {
          title: 'E-Mail',
        },
        login: {
          title: 'Вход',
        },
        last_active: {
          title: 'Последний вход',
        },
        teams: {
          title: 'Команды',
        },
        team: {
          title: 'Команда',
        },
        members_count: {
          title: 'Участники',
        },
        messenger: {
          title: 'Messenger',
        },
      },
      actions: {
        name: 'Действия',
        view_profile: 'Посмотреть профиль',
        edit_profile: 'Редактировать профиль',
        permissions: 'Права доступа',
        reset_password: 'Сбросить пароль',
        reset_2fa: 'Сбросить 2FA',
        unblock: 'Разблокировать',
        block: 'Заблокировать',
        delete_from_team: 'Удалить из команды',
        delete_from_system: 'Удалить из системы',
        login: 'Войти',
      },
      alert: {
        last_seen: 'Последний раз был ',
        days_ago: '{days} дней назад',
      },
    },
  },
  user_info: {
    sub_nav: {
      info: 'Информация',
    },
    personal_information: 'Личная информация',
    edit: 'Редактировать',
    not_specified: 'Не указано',
    login_reg: 'Вход & Регистрация',
    last_login: 'Последний вход',
    reg_date: 'Дата регистрации',
    teams: 'Команды',
    add: 'Добавить',
    add_team: 'Добавить команду',
    delete: 'Удалить',
    affiliates: 'Партнеры',
    pin: 'Закрепить',
    unpin: 'Открепить',
    no_pinned_affiliates: 'Нет закрепленных партнеров',
    no_teams: 'Нет команд',
    note: 'Заметка',
    see_stats: 'Просмотр статистики',
    see_stats_from: 'С',
    see_stats_to: 'До',
    see_stats_overall: 'За все время',
  },
  permissions: {
    teams_select: 'Команды',
    save_changes: 'Сохранить изменения для {teamName}?',
    depends: 'Зависит от: ',
    geo: {
      title: 'Ограничения по гео',
      geo_card_title: 'Ограничения по гео',
      geo_statistics: 'Статистика по странам',

    },
    others: {
      title: 'Прочее',
    },
    live: {
      title: 'Live',
      live_card_title: 'Live',
    },
    reports: {
      title: 'Отчеты',
      reports_card_title: 'Отчеты',
      reports_affiliates_types: 'Виды партнерских отчетов',
      reports_see_stats_after_cpa_conversion: 'Посмотреть статистику после конверсии CPA',
      reports_group_by: 'Сгруппировать отчет по',
      reports_view_columns: 'Просмотр колонок',
    },
    affiliates: {
      title: 'Партнеры',
      affiliates_card_title: 'Партнеры',
      affiliates_actions_title: 'Действия',
      affiliates_security_title: 'Безопасность',
      affiliates_status_change_title: 'Смена статуса',
      affiliates_types_view: 'Посмотреть тип партнеров',
      affiliates_types_edit: 'Редактировать типа партнеров',
      affiliates_sub_affiliates: 'Реферальная программа',
      affiliates_sub_affiliates_edit_settings: 'Активировать реферальну программу',
      affiliates_sub_affiliates_max_percent: 'Максимальный процент по реферальной программе',
      affiliates_activity_log: 'Журнал активности',
      affiliates_notes: 'Заметки',
      affiliates_notifications: 'Уведомления',
    },
    offers: {
      title: 'Офферы',
      offers_card_title: 'Офферы',
      offers_revshare: 'RevShare',
      offers_revshare_max_percent_sport: 'Максимальный RS по ставкам на спорт',
      offers_revshare_max_percent_casino: 'Максимальный RS по ставкам на казино',
      offers_cpa: 'CPA',
      offers_cpa_max_payout: 'Максимальная CPA выплата',
      offers_cpa_max_payout_currency: 'Валюта макс. CPA выплаты',
      offers_cpa_min_payout_deposits_sum_ratio: 'Мин соотношение суммы депозитов к выплате',
      not_selected: 'Не выбрана',

    },
    players: {
      title: 'Игроки',
      players_card_title: 'Игроки',
      players_card_other: 'Другие варианты',
      players_view_columns: 'Посмотреть колонки по игрокам',
      players_view_affiliate_id: 'ID партнера',
      players_view_affiliate_email: 'Email партнера',
      players_view_external_id: 'Внешний ID',
      players_view_country_name: 'Название страны',
      players_view_country_code: 'Код страны',
      players_view_phone: 'Контактный номер',
      players_view_email: 'Email',
      players_view_name_first: 'Имя',
      players_view_name_last: 'Фамилия',
      players_view_virtual_acc: 'Виртуальный аккаунт',
      players_view_created_at: 'Создан',
      players_view_updated_at: 'Обновлен',
      players_view_last_active_at: 'Последняя активность',
      players_view_subid1: 'SubID 1',
      players_view_subid2: 'SubID 2',
      players_view_subid3: 'SubID 3',
      players_view_subid4: 'SubID 4',
      players_view_subid5: 'SubID 5',
      players_view_profit_category: 'Категория профита',

      personal_information: 'Личная информация',
      contacts: 'Контакты',
      country: 'Страна',
      affiliate: 'Партнер',
      visit: 'Информация о посещении',
      subId: 'SubId',
    },
    payments: {
      title: 'Выплаты',
      payments_card_title: 'Выплаты',
    },
    tools: {
      title: 'Инструменты',
      tools_promo_codes_checker: 'Проверка промокодов',
      tools_promo_landings: ' Лендинги по лигам',

    },
    crm: {
      title: 'CRM',
      segments: 'Сегменты',
      activities: 'Активности',

      segments_view: 'Посмотреть Сегменты',
      segments_review: 'Проверить Сегменты',
      segments_edit: 'Редактировать Сегменты',

      activities_view: 'Посмотреть Активности',
      activities_review: 'Проверить Активности',
      activities_edit: 'Редактировать Активности',
      activities_run: 'Запуск Активности',
    },
    teams: {
      title: 'Команды',
      teams_card_title: 'Команды',
      teams_view: 'Посмотреть команды',
      teams_edit: 'Редактировать команды',
      teams_assign_admins: 'Назначить администратора командам',
      teams_change_permissions: 'Редактировать права доступа',
    },
    independent: 'Незав. партнеры',
    own_affiliates: 'Собств. партнеры',
    teammates_affiliates: 'Общие партнеры',
    other_teams_affiliates: 'Партнеры др. команд',
    allow_all: 'Все',
    deny_all: 'Все запрещено',
    allow_selected: 'Только выбранные',
    deny_selected: 'Все кроме',
    add_country: 'Выбрать страны',
    add_teammates: 'Выбрать партнеров по команде',
    on_review: 'На рассмотрении',
    approved: 'Одобрено',
    declined: 'Отклонено',
    not_paid: 'Не выплачено',
    for_rework: 'На доработке',
    processing: 'В обработке',
    paid: 'Выплачено',
    failed: 'Неудачно',
    status: 'Статус',
    allow: 'Разрешено',
    deny: 'Запрещено',
    all: 'Все',
    own: 'Собств.',
    teammates: 'Общие',
    deny_in: '<span>Запрещено</span> в {city}',
    inherit: 'Наслед.',
    media_buying: {
      title: 'Медиабаинг',
      payments: 'Запросы на оплату',
      payments_moderation: 'Модерация платежей',
      payments_payout: 'Проведение платежей',
      posts: 'Публикации',
      reports: 'Отчеты',
      statuses: 'Видимые статусы',
      media_buying_payments_affiliates_types_view: 'Посмотреть виды платежей',
      media_buying_payments_affiliates_types_review: 'Проверить виды платежей',
      media_buying_reports_affiliates_types_view: 'Виды партнерских отчетов',
    },
    dms: {
      title: 'Система управления доменами',
      dns_hosting_accounts: 'Учетные записи хостинга DNS',
      domain_registrar_accounts: 'Учетные записи рег. доменов',
      dedicated_site_domains: 'Выделенные домены',
      platform_servers: 'Серверы платформы',
      platform_service_domains: 'Домены сервисов платформы',
      service_rotation_groups: 'Сервисные ротационные группы',
    },

    list: {
      last_bets_and_deposits_view: 'Посмотреть последние ставки & депозиты',
      top_events_view: 'Посмотреть события в топе',

      reports_view: 'Посмотреть отчет',
      reports_group_by_affiliates: 'Партнерам',
      reports_group_by_countries: 'Странам',
      reports_group_by_sub_affiliates: 'Суб-партнерам',
      reports_group_by_payments: 'Способам оплаты',
      reports_view_revenue_columns: 'Заработок',
      reports_view_profit_columns: 'Прибыль',
      reports_view_deposits_sum_columns: 'Сумма депозитов',
      reports_view_deposits_count_columns: 'Количество депозитов',
      reports_view_withdrawals_sum_columns: 'Сумма выводов',
      reports_view_withdrawals_count_columns: 'Количество выводов',
      reports_view_sport_bets_sum_columns: 'Сумма ставок на спорт',
      reports_view_sport_bets_count_columns: 'Количество ставок на спорт',
      reports_view_casino_bets_sum_columns: 'Сумма ставок на казино',
      reports_view_casino_bets_count_columns: 'Количество ставок на казино',

      offers_edit: 'Редактировать оффер',
      offers_revshare_edit_settings: 'Установить RevShare',
      offers_cpa_edit_settings: 'Установить CPA',

      affiliates_sub_affiliates_edit_settings: 'Активировать реферальну программу',
      affiliates_rotation_groups_edit: 'Редактирование ротационных групп партнера',
      affiliates_edit: 'Редактировать партнеров',
      affiliates_view: 'Посмотреть партнеров',

      players_view: 'Посмотреть игроков',
      players_virtual_edit_settings: 'Редактировать виртуальный аккаунт',
      players_assign_affiliate: 'Редактировать привязку к партнерам',

      payments_view: 'Посмотреть выплаты',
      payments_review_actions: 'Выплаты на рассмотрении',
      payments_chargebacks_actions: 'Сделать списание',

      media_buying_posts_affiliates_types_view: 'Посмотреть виды постов',
      media_buying_posts_affiliates_types_review: 'Проверить виды постов',
      media_buying_posts_view: 'Посмотреть посты',
      media_buying_posts_review: 'Проверить посты',
      media_buying_payments_view: 'Посмотреть выплаты',
      media_buying_payments_review: 'Проверить выплаты',
      media_buying_payments_affiliates_types_view: 'Посмотреть виды платежей',
      media_buying_reports_view: 'Посмотреть отчет',
      media_buying_reports_affiliates_types_view: 'Виды партнерских отчетов',

      tools_promo_codes_checker_view: 'Посмотреть проверку промокодов',
      tools_promo_landings_view: 'Посмотреть лендинги по лигам',
      tools_promo_landings_edit: 'Редактировать лендинги по лигам',
      marketing_materials_view: 'Посмотреть промо-материалы',
      marketing_materials_edit: 'Редактировать промо-материалы',

      dns_hosting_accounts_view: 'Посмотреть учетные записи хостинга',
      dns_hosting_accounts_edit: 'Редактировать учетные записи хостинга',
      domain_registrar_accounts_view: 'Посмотреть учетные записи регистратора доменов',
      domain_registrar_accounts_edit: 'Редактировать учетные записи регистратора',
      platform_servers_view: 'Посмотреть серверы',
      platform_servers_edit: 'Редактировать серверы',
      platform_servers_can_run_jobs: 'Запуск заданий',
      platform_service_domains_view: 'Посмотреть домены',
      platform_service_domains_edit: 'Редактировать домены',
      platform_service_domains_can_run_jobs: 'Запуск заданий',
      platform_betting_domains_view: 'Посмотреть выделеные домены',
      platform_betting_domains_edit: 'Редактировать выделеные домены',
      service_rotation_groups_view: 'Посмотреть ротационные группы',
      service_rotation_groups_edit: 'Редактировать ротационные группы',
      service_rotation_groups_domains_view: 'Посмотреть домены',
      service_rotation_groups_domains_edit: 'Редактировать домены',

      affiliates_activity_log_view: 'Просмотр журнала активности',
      affiliates_notes_view: 'Просмотр заметок',
      affiliates_notes_edit: 'Редактирование заметок',

      affiliates_can_login: 'Войти как партнер',
      affiliates_password_edit: 'Изменить пароль',
      affiliates_payment_info_edit: 'Редактировать информацию о платеже',
      affiliates_domain_change_notifications_edit: 'Изменить уведомления об изменении домена',
      affiliates_can_approve: 'Одобрить',
      affiliates_can_decline: 'Отклонить',
      affiliates_can_block: 'Заблокировать',
      affiliates_can_unblock: 'Разблокировать',
      affiliates_can_revoke: 'На проверку',
      affiliates_reporting_settings_rs_profit_visibility_edit: 'Редактировать отображение статистики по RS',
      affiliates_reporting_settings_cpa_profit_visibility_edit: 'Редактировать отображение статистики по CPA',
      affiliates_email_edit: 'Редактировать Email',
      affiliates_payments_can_create: 'Создать платежи',
      affiliates_2fa_can_reset: 'Сбросить 2FA',
      affiliates_media_buying_edit: 'Редактировать настройки Media Buying',
      affiliates_promo_codes_edit: 'Редактировать промокоды',
      affiliates_balance_edit: 'Редактировать баланс',
      affiliates_limits_edit: 'Редактировать лимиты',
      affiliates_commissions_edit: 'Редактировать комиссии',

      crm_segments_view: 'Посмотреть Сегменты',
      crm_segments_review: 'Проверить Сегменты',
      crm_segments_edit: 'Редактировать Сегменты',
      crm_activities_view: 'Посмотреть Активности',
      crm_activities_review: 'Проверить Активности',
      crm_activities_edit: 'Редактировать Активности',
      crm_activities_run: 'Запуск Активности',

      affiliates_notifications_view: 'Просмотр уведомлений',
      affiliates_notifications_edit: 'Отправка уведомлений',
    },
  },
  team_popup: {
    delete_title_system: 'Удалить {name}?',
    delete_msg_system: 'Вы уверены, что хотите удалить <span>{name}</span> из системы?',
    delete_action_system: 'Удалить',

    delete_title_team: 'Удалить {name} из {teamName}?',
    delete_msg_team: 'Удалить <span>{name}</span> из <span>{teamName}</span> и открепить всех партнеров?',
    delete_action_team: 'Удалить',

    block_title: 'Заблокировать {name}',
    block_msg: 'Вы уверены, что хотите заблокировать <span>{name}</span>?',
    block_action: 'Заблокировать',

    unblock_title: 'Разблокировать {name}',
    unblock_msg: 'Вы уверены, что хотите разблокировать <span>{name}</span>?',
    unblock_action: 'Разблокировать',

    reset_2fa_title: 'Сбросить 2FA для {name}?',
    reset_2fa_msg: 'Вы уверены, что хотите сбросить 2FA для <span>{name}</span>?',
    reset_2fa_action: 'Сбросить 2FA',

    unpin_title: 'Открепить {affiliateName} от {name}?',
    unpin_msg: 'Вы уверены, что хотите открепить <span>{affiliateName}</span> от <span>{name}</span>?',
    unpin_action: 'Открепить',

    reset_pass_title: 'Сбросить пароль для {name}?',
    reset_pass_msg: 'Вы уверены, что хотите сбросить пароль для <span>{name}</span>?',
    reset_pass_action: 'Сбросить пароль',

    delete_team_title: 'Удалить {name}?',
    delete_team_msg: 'Вы уверены, что хотите удалить <span>{name}</span>?',
    delete_team_action: 'Удалить',

    edit_user_title: 'Редактировать пользователя {name}',

    name_label: 'Имя',
    name_placeholder: 'Имя',

    email_label: 'E-Mail',
    email_placeholder: 'E-Mail',

    login_label: 'Логин',
    login_placeholder: 'Логин',

    timezone_label: 'Часовой пояс',
    timezone_placeholder: 'Часовой пояс',

    see_stats_label: 'Просмотр статистики',
    see_stats_placeholder: 'Выберите дату',
    see_stats_overall: 'За все время',

    messenger_label: 'Messenger',
    messenger_placeholder: 'Messenger',

    messenger_id_label: 'Messenger ID',
    messenger_id_placeholder: 'Messenger ID',

    note_label: 'Заметка',
    note_placeholder: 'Заметка о пользователе',

    new_pass_title: 'Новый пароль',
    create_user_title: 'Создать пользователя',

    login_input: 'Логин',
    pass_input: 'Пароль',

    team_name_label: 'Название команды',
    team_name_placeholder: 'Название команды',

    copy_button: 'Копировать',
    close_button: 'Закрыть',
    cancel_button: 'Отменить',
    save_button: 'Сохранить',
    create_button: 'Создать',
    add_teams_button: 'Добавить команды',
    add_users_button: 'Добавить пользователей',
    add_user_button: 'Добавить пользователя',
    add_team_button: 'Добавить команду',
    edit_team_button: 'Редактировать команду',
    add_affiliates_button: 'Добавить партнеров',
    add_affiliate_button: 'Добавить партнера',

    add_user_in_teams_title: 'Добавить {name} к следующим командам',
    add_users_in_team_title: 'Добавить пользователя в {teamName}',
    add_user_in_team_title: 'Добавить пользователя в {teamName}',
    add_affiliates_to_user_title: 'Закрепить партнеров за {name}',
    user_permissions_title: '{name} Права доступа',
  },
};
