import { generateDependsOnConfig } from '../helpers/permissions';

export const requiresForConfig = {
  reports_view: [
    'reports_group_by_affiliates',
    'reports_group_by_countries',
    'reports_group_by_sub_affiliates',
    'reports_group_by_payments',
    'reports_view_revenue_columns',
    'reports_view_profit_columns',
    'reports_view_deposits_sum_columns',
    'reports_view_deposits_count_columns',
    'reports_view_withdrawals_sum_columns',
    'reports_view_withdrawals_count_columns',
    'reports_view_sport_bets_sum_columns',
    'reports_view_sport_bets_count_columns',
    'reports_view_casino_bets_sum_columns',
    'reports_view_casino_bets_count_columns',

    'reports_affiliates_types',
    'reports_view_affiliates_visibility_teammates_id',
  ],
  affiliates_view: [
    'affiliates_edit',
    'affiliates_rotation_groups_edit',
    'affiliates_sub_affiliates_edit_settings',
    'offers_edit',
    'offers_revshare_edit_settings',
    'offers_cpa_edit_settings',
    'affiliates_activity_log_view',
    'affiliates_notes_view',
    'affiliates_notes_edit',
    'affiliates_can_login',
    'affiliates_password_edit',
    'affiliates_payment_info_edit',
    'affiliates_domain_change_notifications_edit',
    'affiliates_can_approve',
    'affiliates_can_decline',
    'affiliates_can_block',
    'affiliates_can_unblock',
    'affiliates_can_revoke',
    'affiliates_reporting_settings_rs_profit_visibility_edit',
    'affiliates_reporting_settings_cpa_profit_visibility_edit',
    'affiliates_email_edit',
    'affiliates_payments_can_create',
    'affiliates_2fa_can_reset',
    'affiliates_media_buying_edit',
    'affiliates_promo_codes_edit',
    'affiliates_balance_edit',
    'affiliates_limits_edit',

    'affiliates_types_view',
    'affiliates_types_edit',
    'affiliates_edit_affiliates_visibility_teammates_id',
    'affiliates_view_affiliates_visibility_teammates_id',

    'affiliates_sub_affiliates_max_percent',
    'offers_revshare_max_percent_sport',
    'offers_revshare_max_percent_casino',
    'offers_cpa_max_payout',
    'offers_cpa_max_payout_currency_code',
    'offers_cpa_min_payout_deposits_sum_ratio',

    'affiliates_notifications_view',
    'affiliates_notifications_edit',
  ],
  affiliates_edit: [
    'affiliates_rotation_groups_edit',
    'offers_edit',
    'affiliates_sub_affiliates_edit_settings',
    'offers_revshare_edit_settings',
    'offers_cpa_edit_settings',
    'affiliates_notes_edit',
    'affiliates_password_edit',
    'affiliates_payment_info_edit',
    'affiliates_domain_change_notifications_edit',
    'affiliates_can_approve',
    'affiliates_can_decline',
    'affiliates_can_block',
    'affiliates_can_unblock',
    'affiliates_can_revoke',
    'affiliates_reporting_settings_rs_profit_visibility_edit',
    'affiliates_reporting_settings_cpa_profit_visibility_edit',
    'affiliates_email_edit',
    'affiliates_payments_can_create',
    'affiliates_2fa_can_reset',
    'affiliates_media_buying_edit',
    'affiliates_promo_codes_edit',
    'affiliates_balance_edit',
    'affiliates_limits_edit',

    'affiliates_types_edit',
    'affiliates_edit_affiliates_visibility_teammates_id',

    'affiliates_sub_affiliates_max_percent',
    'offers_revshare_max_percent_sport',
    'offers_revshare_max_percent_casino',
    'offers_cpa_max_payout',
    'offers_cpa_max_payout_currency_code',
    'offers_cpa_min_payout_deposits_sum_ratio',

    'affiliates_notifications_edit',
  ],
  affiliates_sub_affiliates_edit_settings: [
    'affiliates_sub_affiliates_max_percent',
  ],
  offers_edit: [
    'offers_revshare_edit_settings',
    'offers_cpa_edit_settings',
    'offers_revshare_max_percent_sport',
    'offers_revshare_max_percent_casino',
    'offers_cpa_max_payout',
    'offers_cpa_max_payout_currency_code',
    'offers_cpa_min_payout_deposits_sum_ratio',
  ],
  offers_revshare_edit_settings: [
    'offers_revshare_max_percent_sport',
    'offers_revshare_max_percent_casino',
  ],
  offers_cpa_edit_settings: [
    'offers_cpa_max_payout',
    'offers_cpa_max_payout_currency_code',
    'offers_cpa_min_payout_deposits_sum_ratio',
  ],
  players_view: [
    'players_virtual_edit_settings',
    'players_assign_affiliate',

    'players_available_property_groups',
  ],
  dns_hosting_accounts_view: [
    'dns_hosting_accounts_edit',
    'platform_service_domains_edit',
  ],
  domain_registrar_accounts_view: [
    'domain_registrar_accounts_edit',
    'platform_service_domains_edit',
  ],
  platform_betting_domains_view: [
    'platform_betting_domains_edit',
  ],
  platform_servers_view: [
    'platform_servers_edit',
    'platform_servers_can_run_jobs',
    'platform_service_domains_edit',
  ],
  platform_service_domains_view: [
    'platform_service_domains_edit',
    'platform_service_domains_can_run_jobs',
    'service_rotation_groups_domains_view',
    'service_rotation_groups_domains_edit',
  ],
  service_rotation_groups_view: [
    'service_rotation_groups_edit',
    'service_rotation_groups_domains_view',
    'service_rotation_groups_domains_edit',
    'affiliates_rotation_groups_edit',
  ],
  service_rotation_groups_domains_view: [
    'service_rotation_groups_domains_edit',
  ],
  payments_view: [
    'payments_review_actions',
    'payments_chargebacks_actions',
    'affiliates_payments_can_create',
  ],
  tools_promo_landings_view: [
    'tools_promo_landings_edit',
  ],
  affiliates_notes_view: [
    'affiliates_notes_edit',
  ],
  media_buying_posts_view: [
    'media_buying_posts_review',
    'media_buying_posts_affiliates_types_view',
    'media_buying_posts_affiliates_types_review',
    'media_buying_posts_statuses_visible',
  ],
  media_buying_posts_review: [
    'media_buying_posts_affiliates_types_review',
  ],
  media_buying_payments_view: [
    'media_buying_payments_review',
    'media_buying_payments_affiliates_types_view',
    'media_buying_payments_affiliates_types_review',

    'media_buying_payments_moderation_statuses_visible',
    'media_buying_payments_payout_statuses_visible',

    'media_buying_posts_view_affiliates_visibility_teammates_id',
    'media_buying_posts_review_affiliates_visibility_teammates_id',
    'media_buying_payments_view_affiliates_visibility_teammates_id',
    'media_buying_payments_review_affiliates_visibility_teammates_id',
  ],
  media_buying_payments_review: [
    'media_buying_payments_affiliates_types_review',

    'media_buying_posts_review_affiliates_visibility_teammates_id',
    'media_buying_payments_review_affiliates_visibility_teammates_id',
  ],
  media_buying_reports_view: [
    'media_buying_reports_affiliates_types_view',
    'media_buying_reports_view_affiliates_visibility_teammates_id',
  ],
  marketing_materials_view: [
    'marketing_materials_edit',
  ],

  crm_segments_view: [
    'crm_segments_edit',
  ],
  crm_segments_edit: [
    'crm_segments_review',
  ],
  crm_activities_view: [
    'crm_activities_edit',
    'crm_activities_review',
    'crm_activities_run',
  ],

  affiliates_notifications_view: [
    'affiliates_notifications_edit',
  ],
  affiliates_notifications_edit: [],
};

export const dependsOnConfig = generateDependsOnConfig(requiresForConfig);
