<template>
  <div
    v-loading="isDataLoading"
    class="player"
  >
    <div class="head">
      <div class="wrapper">
        <div class="nav">
          <router-link
            :to="{
              path: backUrl(),
            }"
            class="back"
          >
            {{ $t('players.menu.playersList') }}
          </router-link>
          <span class="title">> {{ info.site_player_id }}</span>
        </div>
        <span class="id">{{ info.site_player_id }}</span>
        <div class="sub-nav">
          <nav class="menu">
            <router-link
              v-for="item in nav"
              :key="item.name"
              :to="{
                path: `/players/${id}/${item.link}`,
                query: $route.query,
              }"
              :class="{active : isLinkActive(item.name)}"
              class="item"
            >
              <span
                :title="$t(`players.nav.${item.name}`)"
                class="link-title"
              >{{ $t(`players.nav.${item.name}`) }}</span>
            </router-link>
          </nav>
        </div>
      </div>
    </div>
    <router-view :info.sync="info" />
  </div>
</template>

<script>

import detectPermissions from '../../service/detectPermissions';

export default {
  name: 'Player',
  data() {
    return {
      id: '',
      info: {},
      isDataLoading: false,
      currentCard: '',
      getPlayerData: Function,
    };
  },
  computed: {
    nav() {
      const nav = [
        {
          name: 'info',
          link: 'info',
        },
      ];

      if (this.calcPermissions()) {
        nav.push({
          name: 'last',
          link: 'last-operations',
        });
      }

      if (this.checkPermission('crm_activities_view')) {
        nav.push({
          name: 'crm',
          link: 'crm',
        });
      }

      return nav;
    },
  },
  watch: {
    id() {
      this.getPlayerData();
    },
    $route() {
      this.currentCard = this.$route.matched[2].meta.view;
      if (this.id !== this.$route.params.id) {
        this.id = this.$route.params.id;
      }
    },
  },
  created() {
    if (detectPermissions.checkRequest('players_view')) {
      this.getPlayerData = this.getPlayerDataD;
      this.id = this.$route.params.id;
      this.currentCard = this.$route.meta.view;
    }
    detectPermissions.checkRoute('players_view');
  },
  methods: {
    backUrl() {
      const re = /back=/gi;
      return this.$route.query.param_playerlist ? this.$route.query.param_playerlist.replace(re, '') : '/players/list';
    },
    checkPermission(key) {
      if (this.$store.getters['auth/adminAcl'].is_superuser) return true;

      return this.$store.getters['auth/currentAcl'][key] !== 'deny';
    },
    calcPermissions() {
      return this.$store.getters['auth/currentAcl'].last_bets_and_deposits_view === 'allow' || (this.$store.getters['auth/adminAcl'].is_superuser
          && !localStorage.getItem('active-team'));
    },
    isLinkActive(name) {
      return this.currentCard === name;
    },
    getPlayerDataD() {
      this.isDataLoading = true;
      this.$api
        .getPlayerInfo(this.id)
        .then(async (response) => {
          let affiliate_offer_name = null;
          if (response.data.payload.affiliate_offer_id) {
            const { data } = await this.$api.getAffiliateOffer(response.data.payload.affiliate_offer_id);
            affiliate_offer_name = data.payload.full_name;
          }

          this.info = this.$_.cloneDeep({
            ...response.data.payload,
            affiliate_offer_name,
          });
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.player {
  .head {
    display: flex;
    margin: 0 auto 16px;
    min-width: 1140px;
    width: 90%;
    padding: 16px 0 0;
    border-bottom: 1px solid #eaeaea;
  }
}
</style>
