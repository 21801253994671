<template>
  <div
    v-loading="isDataLoading"
    class="player-info"
  >
    <link-affiliate
      v-show="isPopupOpen"
      :is-open="isPopupOpen"
      :data="dataAffiliate"
      :site-id="siteId"
      :action="1"
      :leagues="1"
      :league-id="1"
      :match="1"
      @close="onClosePopup"
    />
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirm.type"
      :action-name="$t('players.confirm.action_3')"
      :action-disabled="!virtualActionDisabled"
      action="Change"
      action-icon="check"
      @save="changeStatus"
    >
      <div slot="title">
        {{ confirm.title }}
      </div>
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
      <div
        class="body__msg body__msg--padding"
        v-html="confirm.info"
      />
      <ui-input
        v-model="captcha"
        :placeholder="$t('players.playersList.confirm.yes')"
        width="auto"
      />
    </ui-confirm>
    <div class="wrapper">
      <div class="sidebar">
        <div class="card">
          <div class="title">
            {{ $t('players.info.personal_info') }}
          </div>
          <div class="card-content">
            <div class="field">
              <div
                class="values ui-fd-column"
              >
                <span class="name">
                  {{ info.first_name === undefined && info.last_name === undefined ? 'N/A' : info.first_name || info.last_name ? `${ info.first_name}
                ${ info.last_name }` : this.$t('affiliates.cardView.emptyField') }}
                </span>
                <span v-if="info.last_active_at" class="sub-item">Last Active: <span>{{ $moment(info.last_active_at).format('DD MMM YYYY HH:mm') }}</span></span>
                <span v-if="info.created_at" class="sub-item ui-m-md-t">Registration Date: <span>{{ $moment(info.created_at).format('DD MMM YYYY HH:mm') }}</span></span>
                <span v-if="info.registration_type" class="sub-item ui-m-md-t">Registration Type: <span>{{ info.registration_type }}</span></span>
              </div>
            </div>
            <div class="field">
              <div class="values">
                <div class="phone">
                  <ui-icon
                    :size="12"
                    name="phone"
                    lib="fa"
                    :color="$theme.accentColor"
                  />
                  {{ info.phone_number === undefined ? 'N/A' : info.phone_number ? info.phone_number : this.$t('affiliates.cardView.emptyField') }}
                </div>
              </div>
            </div>
            <div class="field">
              <div class="values">
                <div class="email">
                  <ui-icon
                    :size="12"
                    name="envelope"
                    lib="fa"
                    :color="$theme.accentColor"
                  />
                  {{ info.email === undefined ? 'N/A' : info.email ? info.email : this.$t('affiliates.cardView.emptyField') }}
                </div>
              </div>
            </div>
            <div class="field">
              <span class="label">{{ $t('players.info.country') }}</span>
              <div class="values">
                {{ info.player_country_name === undefined ? 'N/A' : info.player_country_name
                  ? info.player_country_name : this.$t('affiliates.cardView.emptyField') }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="info.is_virtual !== undefined"
          class="card"
        >
          <div class="title">
            <span>{{ $t('players.info.verif_account') }}</span>
            <span
              v-if="editVirtual"
              :style="{color: this.$theme.accentColor}"
              class="action"
              @click="clickEditHandler"
            >
              <ui-icon
                name="pen"
                :color="this.$theme.accentColor"
                lib="fa"
              />
              {{ $t('players.info.edit') }}
            </span>
          </div>
          <div class="card-content">
            <div class="field">
              <span
                :style="{
                  color: info.is_virtual ? this.$theme.accentColor : '#303634'
                }"
                class="value"
              >{{ info.is_virtual ? $t('players.playersList.yes') : $t('players.playersList.no') }}
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="info.affiliate_email !== undefined"
          class="card"
        >
          <div class="title">
            <span>{{ $t('players.info.aff') }}</span>
            <span
              v-if="editAssignAffiliate && info.affiliate_id !== undefined && !info.is_virtual && info.is_virtual !== undefined "
              :style="{color: info.affiliate_email ? $theme.dangerColor : $theme.accentColor}"
              class="action"
              @click="checkSensitiveAction('clickEditAffiliateHandler')"
            >
              <ui-icon
                name="pen"
                :color="info.affiliate_email ? $theme.dangerColor : $theme.accentColor"
                lib="fa"
              />
              {{ $t('players.info.edit') }}
            </span>
          </div>
          <div class="card-content">
            <div class="field">
              <router-link
                v-if="info.affiliate_email !== undefined && info.affiliate_id !== undefined"
                :to="{path: `/affiliates/${info.affiliate_id}/info`,}"
                class="value link"
              >
                {{ info.affiliate_email ? info.affiliate_email : '' }}
              </router-link>
              <span v-else-if="info.affiliate_email !== undefined && info.affiliate_id === undefined">{{ info.affiliate_email ? info.affiliate_email : '' }}</span>
            </div>

            <div v-if="info.affiliate_offer_name" class="field">
              <span class="label">{{ $t('players.info.offer') }}</span>
              <span>{{ info.affiliate_offer_name }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="ui-d-flex ui-fd-column ui-fg-1">
        <div class="stats">
          <ui-currency-picker
            :value="currencyCode"
            :currencies="customerCurrencies"
            @input="changeCurrency"
          />
        </div>

        <div class="stats ui-m-xl-t">
          <div
            v-if="stats.top_payment_systems"
            class="card"
          >
            <div class="title">
              {{ $t('players.info.payment_method') }}
            </div>
            <div class="card-content">
              <div v-if="stats.top_payment_systems.length > 0">
                <div
                  v-for="stat in stats.top_payment_systems"
                  :key="stat.stat_name"
                  class="field"
                >
                  <span class="label">{{ stat.stat_name ? stat.stat_name : '—' }}</span>
                  <div class="values">
                    {{ formatStat(stat.stat_count, 'number') }} /
                    {{ formatStat(stat.stat_percent, 'percent') }} /
                    {{ formatStat(stat.stat_sum, 'currency') }}
                  </div>
                </div>
              </div>
              <div
                v-if="stats.top_payment_systems.length === 0"
                class="field"
              >
                <div class="field__empty-text">
                  <ui-icon
                    :size="32"
                    name="folder-open"
                    lib="fa"
                    substyle="fal"
                    color="#a6b2c4"
                  />
                  <div>{{ $t('players.info.no_data') }}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="stats.favourite_sports"
            class="card"
          >
            <div class="title">
              {{ $t('players.info.top_sport') }}
            </div>
            <div class="card-content">
              <div v-if="stats.favourite_sports.length > 0">
                <div
                  v-for="stat in stats.favourite_sports"
                  :key="stat.stat_name"
                  class="field"
                >
                  <span class="label">{{ stat.stat_name }}</span>
                  <div class="values">
                    {{ formatStat(stat.stat_count, 'number') }} /
                    {{ formatStat(stat.stat_percent, 'percent') }} /
                    {{ formatStat(stat.stat_sum, 'currency') }}
                  </div>
                </div>
              </div>
              <div
                v-if="stats.favourite_sports.length === 0"
                class="field"
              >
                <div class="field__empty-text">
                  <ui-icon
                    :size="32"
                    name="folder-open"
                    lib="fa"
                    substyle="fal"
                    color="#a6b2c4"
                  />
                  <div>{{ $t('players.info.no_data') }}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="stats.favourite_leagues"
            class="card"
          >
            <div class="title">
              {{ $t('players.info.top_leagues') }}
            </div>
            <div class="card-content">
              <div v-if="stats.favourite_leagues.length > 0">
                <div
                  v-for="stat in stats.favourite_leagues"
                  :key="stat.stat_name"
                  class="field"
                >
                  <span class="label">{{ stat.stat_name }}</span>
                  <div class="values">
                    {{ formatStat(stat.stat_count, 'number') }} /
                    {{ formatStat(stat.stat_percent, 'percent') }} /
                    {{ formatStat(stat.stat_sum, 'currency') }}
                  </div>
                </div>
              </div>
              <div
                v-if="stats.favourite_leagues.length === 0"
                class="field"
              >
                <div class="field__empty-text">
                  <ui-icon
                    :size="32"
                    name="folder-open"
                    lib="fa"
                    substyle="fal"
                    color="#a6b2c4"
                  />
                  <div>{{ $t('players.info.no_data') }}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="stats.bets_distribution"
            class="card"
          >
            <div class="title">
              {{ $t('players.info.bets') }}
            </div>
            <div class="card-content">
              <div v-if="stats.bets_distribution.length > 0">
                <div
                  v-for="stat in stats.bets_distribution"
                  :key="stat.stat_name"
                  class="field"
                >
                  <span class="label">{{ stat.stat_name }}</span>
                  <div class="values">
                    {{ formatStat(stat.stat_count, 'number') }} /
                    {{ formatStat(stat.stat_percent, 'percent') }} /
                    {{ formatStat(stat.stat_sum, 'currency') }}
                  </div>
                </div>
              </div>
              <div
                v-if="stats.bets_distribution.length === 0"
                class="field"
              >
                <div class="field__empty-text">
                  <ui-icon
                    :size="32"
                    name="folder-open"
                    lib="fa"
                    substyle="fal"
                    color="#a6b2c4"
                  />
                  <div>{{ $t('players.info.no_data') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LinkAffiliate from '@/components/Popups/LinkAffiliate.vue';
import formatCurrencyMixin from '@/views/mixins/format-currency';
import updateUrl from '@/service/updateUrl';

const LS_KEY = 'PlayerInfo';

export default {
  name: 'PlayerInfo',
  components: {
    LinkAffiliate,
  },
  mixins: [formatCurrencyMixin],
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      dataAffiliate: {},
      isPopupOpen: false,
      id: '',
      stats: {},
      isDataLoading: false,
      currencyCode: '',
      confirm: {
        title: '',
        msg: '',
        type: 'success',
        info: '',
      },
      captcha: '',
    };
  },
  computed: {
    ...mapGetters({
      customerCurrencies: 'misc/customerCurrencies',
    }),
    editVirtual() {
      const isUser = !this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl'].players_virtual_edit_settings
          === 'allow' && !this.info.is_virtual;
      const isSuperUser = this.$store.getters['auth/adminAcl'].is_superuser && !this.info.is_virtual;
      return isSuperUser || isUser;
    },
    editAssignAffiliate() {
      const isUser = !this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl'].players_assign_affiliate
          === 'allow';
      const isSuperUser = this.$store.getters['auth/adminAcl'].is_superuser;
      return isSuperUser || isUser;
    },
    virtualActionDisabled() {
      return this.$t('players.playersList.confirm.yes').toLowerCase() === this.captcha.toLowerCase();
    },
    siteId() {
      return this.$route.query.param_playerlist ? this.$route.query.param_playerlist.split('site_id=').pop().split('&').shift() : '';
    },
  },
  watch: {
    currencyCode(val) {
      this.$ls.set(`${LS_KEY}/currency_code`, val);
      this._completedFilterUrl();
    },
  },
  created() {
    this.$eventBus.$on('clickEditAffiliateHandler', this.clickEditAffiliateHandler);
  },
  beforeDestroy() {
    this.$eventBus.$off('clickEditAffiliateHandler', this.clickEditAffiliateHandler);
  },
  mounted() {
    this.id = this.$route.params.id;
    if (!this.id) {
      this.$router.push('/players');
    }

    const _urlData = updateUrl.getParseParams(this.$route.hash);
    this.currencyCode = this.checkCurrencyCode(this.$_.get(_urlData, ['currency_code'], this.$ls.get(`${LS_KEY}/currency_code`)));

    this.getPlayerStats();
    // this.getPlayerData();
  },
  methods: {
    _query() {
      return {
        currency_code: this.currencyCode,
        param_playerlist: this.$route.query.param_playerlist,
      };
    },

    _completedFilterUrl() {
      const _dataFilters = this._query();
      updateUrl.updateGetParams(_dataFilters);
    },
    changeCurrency(value) {
      this.getPlayerStats(value);
    },
    checkSensitiveAction(action, row) {
      this.$eventBus.$emit('checkSensitiveAction', action, row);
    },

    openConfirm() {
      this.$refs.confirm.open();
    },

    clickEditHandler() {
      this.confirm.title = `${this.$t('players.confirm.title_3_1')} ${this.info.site_player_id} ${this.info.is_virtual ? `${this.$t('players.confirm.title_3_2')}`
        : `${this.$t('players.confirm.title_3_3')}`}`;
      this.confirm.msg = `${this.$t('players.playersList.confirm.msg')}`;
      this.confirm.info = `${this.$t('players.playersList.confirm.msg_1', { player_id: `<span>${this.info.site_player_id}</span>`, yes: `<span>${this.$t('players.playersList.confirm.yes')}</span>` })}`;
      this.confirm.type = 'success';
      this.captcha = '';
      this.openConfirm();
    },

    changeStatus() {
      this.isDataLoading = true;
      this.$api
        .markAsVirtual(this.info.id)
        .then(() => {
          this.$refs.confirm.close();
          this.getPlayerData();
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },

    clickEditAffiliateHandler() {
      this.isPopupOpen = true;
      this.dataAffiliate = this.info;
    },

    getPlayerData() {
      this.$api.getPlayerInfo(this.info.id)
        .then((res) => {
          this.$emit('update:info', res.data.payload);
        })
        .catch(() => {
          this.isDataLoading = false;
        });
    },

    onClosePopup(v) {
      this.isPopupOpen = false;
      if (v === 'reload') {
        this.$api.getPlayerInfo(this.info.id)
          .then((res) => {
            this.$emit('update:info', res.data.payload);
          })
          .catch(() => {
            this.isDataLoading = false;
          });
      }
    },

    getPlayerStats(currencyCode) {
      this.isDataLoading = true;

      if (currencyCode) {
        this.currencyCode = currencyCode;
      }

      this.$api
        .getPlayerStats(this.id, { currency_code: currencyCode })
        .then((response) => {
          this.stats = this.$_.cloneDeep(response.data.payload);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    formatStat(value, format) {
      switch (format) {
      case 'number':
        return `${parseFloat(value, 10).toLocaleString('en-US')}`;
      case 'percent':
        return `${parseFloat(value, 10).toLocaleString('en-US')}%`;
      case 'currency':
        return this.formatCurrency(value);
      default:
        return value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .player-info {
    .wrapper {
      display: flex;
      margin: 0 auto;
      min-width: 1140px;
      width: 90%;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
</style>
